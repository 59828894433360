// ProjectsPage.js

import React, {useEffect} from 'react';
import MiniHero from './MiniHero';
import projectsJson from '../assets/projects.json'
import './ProjectsPage.css';

const projects = projectsJson.projects[0];

const ProjectsPage = (props) => {
  useEffect(() => {
    document.title = props.title;
  }, [props.title])
  return (
    <div className="projects-page">
      <MiniHero title='Projects'></MiniHero>
      {projects.map(project => (
        <div className="project-card">
        <h3>{project.company}</h3>
        <h4>{project.role}</h4>
        <p>{project.description}</p>
        <p>Responsibilities: </p>
        <ul className='project-card-responsibility'>
          {project.responsibilities.map(responsibility => (
            <li>{responsibility}</li>
          ))}
        </ul>
        <p>Technologies: </p>
        <ul className='project-card-technology'>
            {project.technologies.map(technology => (
              <li>{technology}</li>
            ))}
        </ul>
        
        </div>
      ))}
    </div>
  );
}

export default ProjectsPage;